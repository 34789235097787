(function(a,s){typeof exports=="object"&&typeof module<"u"?module.exports=s(require("attributes-parser"),require("moo")):typeof define=="function"&&define.amd?define(["attributes-parser","moo"],s):(a=typeof globalThis<"u"?globalThis:a||self,a.markedCodePreview=s(a.parseAttrs,a.moo))})(this,function(a,s){"use strict";const w=e=>e.replace(/&/g,"&amp;").replace(/"/g,"&quot;").replace(/'/g,"&#39;").replace(/</g,"&lt;").replace(/>/g,"&gt;");function $(e,...t){if(typeof e=="string")return w(e);let r=e[0];for(const[o,n]of t.entries())r=r+w(String(n))+e[o+1];return r}class b extends Error{constructor(t){super(`Missing a value for ${t?`the placeholder: ${t}`:"a placeholder"}`,t),this.name="MissingValueError",this.key=t}}function y(e,t,{ignoreMissing:r=!1,transform:o=({value:n})=>n}={}){if(typeof e!="string")throw new TypeError(`Expected a \`string\` in the first argument, got \`${typeof e}\``);if(typeof t!="object")throw new TypeError(`Expected an \`object\` or \`Array\` in the second argument, got \`${typeof t}\``);const n=(c,i)=>{let p=t;for(const d of i.split("."))p=p?p[d]:void 0;const h=o({value:p,key:i});if(h===void 0){if(r)return c;throw new b(i)}return String(h)},u=c=>(...i)=>$(c(...i)),l=/{{(\d+|[a-z$_][\w\-$]*?(?:\.[\w\-$]*?)*?)}}/gi;l.test(e)&&(e=e.replace(l,u(n)));const f=/{(\d+|[a-z$_][\w\-$]*?(?:\.[\w\-$]*?)*?)}/gi;return e.replace(f,n)}const z=`
<figure class='preview'>
  <figcaption>{title}</figcaption>
  <div class='preview-showcase'>
    {preview}
  </div>
  <div class='preview-code'>
    {code}
  </div>
</figure>
`,m=/\s*preview(?:=(?:"[^"]*"|'[^']*'|[^"'\s]*))?$|preview(?:=(?:"[^"]*"|'[^']*'|[^"'\s]*))?\s*/g;function A(e){return s.compile({placeholder:/\{code\}/,text:s.fallback}).reset(e)}function _(e){const t=s.compile({placeholder:{match:/{[ \t]*?(?:[a-zA-Z_][\w\d.]*?|[\d][\w\d.]+)[ \t]*?}/,value(o){return o.replace(/[ \t]+/g,"")}},chunk:s.fallback}).reset(e);let r="";for(const o of t)r+=o.value;return r}function j(e,t){return{type:"html",block:!0,pre:!1,raw:e,text:t}}function M(e,t){var x,v,T;const{data:r,attrs:o,index:n,parent:u,template:l=z,transformer:f=g=>g}=t,c=A(l),i=(x=e.raw)==null?void 0:x.split(`
`)[0].replace(m,"");e.raw=(v=e.raw)==null?void 0:v.split(`
`).splice(0,1,i).join(`
`),e.lang=(T=e.lang)==null?void 0:T.replace(m,"");const p=f(e.text,o,r),h={...r,...o,preview:p},d=[];for(const{type:g,text:P}of c)if(g==="placeholder")d.push(e);else if(g==="text"){const E=_(P),R=y(E,h,{ignoreMissing:!0});d.push(j(E,R))}u.splice(n,1,...d)}function L(e={}){return{extensions:[{name:"fences",level:"block",tokenizer(t,r){const o={};this.lexer.options.hooks&&this.lexer.options.hooks!==null&&"data"in this.lexer.options.hooks&&Object.assign(o,this.lexer.options.hooks.data),r.forEach((n,u)=>{var i;if(n.type!=="code"||!n.lang)return;const l=(i=n.lang)==null?void 0:i.split(" "),{preview:f,...c}=a(n.lang);f&&M(n,{index:u,parent:r,data:o,attrs:{lang:l,...c},...e})})}}]}}return L});
